#root {
  background-color: white;
  width: 100vw;
  width: 100dvw;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: white black;
  -ms-overflow-style: none;
}

div.generic-grid {
  display: grid;
  min-height: 100vh;
  min-height: 100dvh;
  min-width: 100vw;
  min-width: 100dvw;
  justify-items: center;
  align-items: center;
  grid-template-columns: 1fr;
}

div.generic-grid {
  grid-template-rows: min-content min-content min-content 1fr;
  grid-template-areas:
    'generic-header'
    'generic-main'
    'generic-description'
    'generic-footer';
}

div.generic-grid>div.grid-title {
  background: black;
  grid-area: generic-header;
  justify-self: center;
  height: 100%;
  min-width: 100%;
  padding-top: 1em;
  margin: 0 auto;
  padding-left: 1em;
}

div.generic-grid>div.generic-content {
  background: white;
  grid-area: generic-main;
  align-self: baseline;
  justify-self: center;
  width: 100vw;
  width: 100dvw;
  padding: 1em;
}

div.generic-grid>div.generic-description {
  background: black;
  grid-area: generic-description;
  align-self: baseline;
  justify-self: center;
  width: 100vw;
  width: 100dvw;
  padding: 1em;
}

div.generic-grid>div.generic-footer {
  background: white;
  grid-area: generic-footer;
  justify-self: start;
  align-self: start;
  width: 100vw;
  width: 100dvw;
}